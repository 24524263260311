.subtitle {
  font-weight: normal;
}

.errorsTitle {
  text-align: center;
  font-weight: normal;
}

.content {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 30px;
  margin-bottom: 40px;
  color: #000;
}

.table {
  margin-bottom: 40px;
}

.row {
  display: flex;
  align-items: center;
  text-align: left;
}

.space {
  padding-top: 20px;
}

.col {
  width: 260px;
  text-align: left;
  font-weight: bold;
  padding: 5px 0 5px;
}

.colRight {
  width: calc(100% - 260px);
}

.title {
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 10px;
}

.errorList {
  list-style-type: circle;
  padding-left: 20px;
}

.errorItem {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .content {
    padding: 15px;
  }
  .row {
    flex-direction: column;
    align-items: normal;
  }
  .col {
    width: auto;
  }
  .colRight {
    width: auto;
  }
  .title {
    font-size: 20px;
  }
}
