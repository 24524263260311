.main {
  max-width: 680px;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  padding: 0 20px;
}

.main .header {
  margin-bottom: 32px;
}

.main .content {
  margin-bottom: 42px;
}
