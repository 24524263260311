@font-face {
  font-family: "e-Ukraine";
  src:
    url("/fonts/e-ukraine-bold.woff2") format("woff2"),
    url("/fonts/e-ukraine-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "e-Ukraine";
  src:
    url("/fonts/e-ukraine-medium.woff2") format("woff2"),
    url("/fonts/e-ukraine-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "e-Ukraine";
  src:
    url("/fonts/e-ukraine-regular.woff2") format("woff2"),
    url("/fonts/e-ukraine-regular.woff") format("woff");
  font-weight: 400;
  src:
    url("/fonts/GothamPro/GothamPro.eot?") format("eot"),
    url("/fonts/GothamPro/GothamPro.otf") format("opentype"),
    url("/fonts/GothamPro/GothamPro.woff") format("woff"),
    url("/fonts/GothamPro/GothamPro.ttf") format("truetype"),
    url("/fonts/GothamPro/GothamPro.svg#GothamPro") format("svg");
}

@font-face {
  font-family: "e-Ukraine";
  src:
    url("/fonts/e-ukraine-light.woff2") format("woff2"),
    url("/fonts/e-ukraine-light.woff") format("woff");
  font-weight: 300;
  src:
    url("/fonts/GothamPro-Light/GothamPro-Light.eot?") format("eot"),
    url("/fonts/GothamPro-Light/GothamPro-Light.otf") format("opentype"),
    url("/fonts/GothamPro-Light/GothamPro-Light.woff") format("woff"),
    url("/fonts/GothamPro-Light/GothamPro-Light.ttf") format("truetype"),
    url("/fonts/GothamPro-Light/GothamPro-Light.svg#GothamPro-Light")
      format("svg");
}

@font-face {
  font-family: "e-Ukraine";
  src:
    url("/fonts/e-ukraine-ultralight.woff2") format("woff2"),
    url("/fonts/e-ukraine-ultralight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "e-Ukraine";
  src:
    url("/fonts/e-ukraine-thin.woff2") format("woff2"),
    url("/fonts/e-ukraine-thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

body {
  color: #3c4858;
  font:
    400 12px/1.6 "GothamPro",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  min-height: 100vh;
}

a {
  color: #5893f0;
  text-decoration: none;
}

ul,
ol {
  list-style-type: none;
}

input,
textarea,
button {
  -webkit-appearance: none;
          appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  color: inherit;
  font: inherit;
}

input,
textarea,
button {
  outline: none;
}

.styles_main__2Av88 {
  display: flex;
  flex-flow: column nowrap;
  max-width: 960px;
  min-height: 100vh;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.styles_main__2Av88 main {
  padding: 75px;
  background: #fff;
  box-shadow: 2px 4px 25px 1.2px rgba(204, 204, 204, 0.31);
}

.styles_footer__1rch9 {
  flex: 0 0 40px;
  line-height: 40px;
  text-align: center;
}

@media (max-width: 768px) {
  .styles_main__2Av88 main {
    padding: 15px;
  }
}

.styles_header__1a2tU {
  flex: 0 0 45px;
  margin-bottom: 36px;
  text-align: center;
}

.styles_logo__GcTzU img {
  display: inline-block;
  height: 47px;
}

.styles_faq__2PTBW {
  padding: 0 20px;
  min-width: 980px;
}

.styles_header__23qrY {
  margin-bottom: 72px;
  text-align: left;
}

.styles_logo__23pMu img {
  display: inline-block;
  height: 80px;
}

.styles_main__1cvWe {
  display: flex;
  flex-flow: row nowrap;
}

.styles_aside__2uo0o {
  flex: 0 0 400px;
  padding-right: 20px;
}

.styles_content__10iIj {
  flex: 1 1 auto;
}

.styles_list__CT_rE {
  font-size: 12px;
}

.styles_list__CT_rE li {
  margin-bottom: 16px;
}

.styles_list__CT_rE a {
  display: inline-block;
  text-decoration: none;
  color: #292b37;
}

.styles_active__3Dj4v {
  font-weight: 700;
}

.styles_h1__2nnZ3 {
  color: #292b37;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
}

.styles_h2__1OCD0 {
  color: #292b37;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
}

.styles_h3__1h4TH {
  color: #454545;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.styles_h4__36VGK {
  color: #454545;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}

.styles_h5__3OYY- {
  color: #3d3d3d;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.styles_h6__2UMON {
  color: #454545;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 10px;
}

.styles_textTransform-uppercase__3qyYY {
  text-transform: uppercase;
}

.styles_textTransform-capitalize__jsJ5O {
  text-transform: capitalize;
}

.styles_textTransform-lowercase__3yIVV {
  text-transform: lowercase;
}

.styles_textTransform-initial__1HFjB {
  text-transform: initial;
}

.styles_color-red__3LASC {
  color: red;
}

.styles_main__1q0QG {
  text-align: center;
}

.styles_main__3tBt- {
  text-align: center;
}

.styles_header__3Do4b {
  margin-bottom: 32px;
}

.styles_article__25owR {
  font-size: 16px;
  margin: 0 auto;
}

.styles_narrow_container__3CUKG {
  max-width: 500px;
  margin: 0 auto;
}

.styles_container__3khIj {
  width: 510px;
  height: 600px;
}

.styles_email__3Wiqa {
  display: block;
  font-weight: 700;
}

.styles_theme-border__2RehW.styles_color-red__1u3lG.styles_theme-border__2RehW,
.styles_theme-fill__3wUWU.styles_color-red__1u3lG.styles_theme-border__2RehW {
  background: #fff;
  box-shadow: inset 0 0 0 2px #f65082;
}
.styles_theme-border__2RehW.styles_color-red__1u3lG.styles_theme-border__2RehW.styles_inherit-color__G16Q6,
.styles_theme-fill__3wUWU.styles_color-red__1u3lG.styles_theme-border__2RehW.styles_inherit-color__G16Q6 {
  color: #f65082;
}
.styles_theme-border__2RehW.styles_color-red__1u3lG.styles_theme-border__2RehW:hover,
.styles_theme-fill__3wUWU.styles_color-red__1u3lG.styles_theme-border__2RehW:hover {
  background: rgba(245, 71, 124, 0.8);
}
.styles_theme-border__2RehW.styles_color-red__1u3lG.styles_theme-border__2RehW:active,
.styles_theme-border__2RehW.styles_color-red__1u3lG.styles_theme-border__2RehW.styles_active__2Hu80,
.styles_theme-fill__3wUWU.styles_color-red__1u3lG.styles_theme-border__2RehW:active,
.styles_theme-fill__3wUWU.styles_color-red__1u3lG.styles_theme-border__2RehW.styles_active__2Hu80 {
  box-shadow: none;
}
.styles_theme-border__2RehW.styles_color-red__1u3lG:disabled,
.styles_theme-border__2RehW.styles_color-red__1u3lG.styles_disabled__34Hd4,
.styles_theme-fill__3wUWU.styles_color-red__1u3lG:disabled,
.styles_theme-fill__3wUWU.styles_color-red__1u3lG.styles_disabled__34Hd4 {
  background-image: none;
  box-shadow: inset 0 2px 0 0 #d74472;
}
.styles_theme-border__2RehW.styles_color-red__1u3lG:active,
.styles_theme-border__2RehW.styles_color-red__1u3lG.styles_active__2Hu80,
.styles_theme-fill__3wUWU.styles_color-red__1u3lG:active,
.styles_theme-fill__3wUWU.styles_color-red__1u3lG.styles_active__2Hu80 {
  background-image: none;
  box-shadow: inset 0 2px 0 0 #d74472;
}
.styles_button__2HY5C.styles_color-blue__1sLAR:active {
  background-image: linear-gradient(
    0deg,
    rgba(0, 134, 242, 0.67) 0%,
    rgba(74, 178, 251, 0.67) 100%
  );
}
.styles_button__2HY5C.styles_block__1-EH0 {
  width: 100%;
}
.styles_button__2HY5C.styles_theme-link__1A5JK {
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: auto;
  background: transparent;
  box-shadow: none;
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  color: #548ef0;
}
.styles_button__2HY5C.styles_theme-link__1A5JK:hover,
.styles_button__2HY5C.styles_theme-link__1A5JK.styles_hover__2SgUa,
.styles_button__2HY5C.styles_theme-link__1A5JK.styles_active__2Hu80 {
  text-decoration: underline;
}
.styles_button__2HY5C.styles_theme-link__1A5JK:disabled,
.styles_button__2HY5C.styles_theme-link__1A5JK.styles_disabled__34Hd4 {
  opacity: 1;
  color: #c6d1d7;
}
.styles_button__2HY5C.styles_theme-link__1A5JK.styles_size-small__32DTw {
  font-size: 10px;
}
.styles_button__2HY5C:disabled,
.styles_button__2HY5C.styles_disabled__34Hd4 {
  opacity: 0.5;
  background-image: none;
  pointer-events: none;
  cursor: default;
}
.styles_button__2HY5C.styles_size-small__32DTw {
  padding: 12px 20px;
  font-size: 10px;
}
.styles_button__2HY5C.styles_size-small__32DTw.styles_theme-fill__3wUWU {
  box-shadow: none;
}
.styles_button__2HY5C.styles_size-small__32DTw:active,
.styles_button__2HY5C.styles_size-small__32DTw.styles_active__2Hu80 {
  box-shadow: none;
}
.styles_button__2HY5C.styles_color-green__2NAv7 {
  background: #b9dd2f linear-gradient(0deg, #c2e639 0%, #c2e639 100%);
  color: #292b37;
}
.styles_button__2HY5C.styles_color-green__2NAv7:hover {
  background-image: linear-gradient(180deg, #c2e639 0%, #c2e639 100%);
}
.styles_button__2HY5C.styles_color-green__2NAv7:active,
.styles_button__2HY5C.styles_color-green__2NAv7.styles_active__2Hu80 {
  background-image: none;
}
.styles_button__2HY5C.styles_color-green__2NAv7:disabled,
.styles_button__2HY5C.styles_color-green__2NAv7.styles_disabled__34Hd4 {
  background-image: none;
}
.styles_button__2HY5C.styles_color-green__2NAv7.styles_theme-border__2RehW {
  background: #fff;
}
.styles_button__2HY5C.styles_color-green__2NAv7.styles_theme-border__2RehW.styles_inherit-color__G16Q6 {
  color: #b9dd2f;
}
.styles_button__2HY5C.styles_color-green__2NAv7.styles_theme-border__2RehW:hover {
  background: rgba(85, 235, 63, 0.8);
}
.styles_button__2HY5C.styles_color-green__2NAv7.styles_theme-border__2RehW:active,
.styles_button__2HY5C.styles_color-green__2NAv7.styles_theme-border__2RehW.styles_active__2Hu80 {
  box-shadow: none;
}
.styles_button__2HY5C {
  display: inline-block;
  padding: 25px 50px;
  min-width: 300px;
  line-height: 1.2;
  color: #fff;
  background: #ff5a00 linear-gradient(0deg, #ff5a00 0%, #f8752e 100%);
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-user-select: none;
  user-select: none;
  margin-bottom: 10px;
  cursor: pointer;
}
.styles_button__2HY5C .styles_icon__1OqJO {
  margin-right: 5px;
}
.styles_button__2HY5C .styles_icon__1OqJO i {
  vertical-align: middle;
}
.styles_button__2HY5C.styles_color-blue__1sLAR {
  background-color: #2292f2;
  background-image: linear-gradient(
    0deg,
    rgba(0, 134, 242, 0.67) 0%,
    rgba(74, 178, 251, 0.67) 100%
  );
}
.styles_button__2HY5C.styles_color-blue__1sLAR:hover {
  background-image: linear-gradient(
    0deg,
    rgba(74, 178, 251, 0.67) 0%,
    rgba(74, 178, 251, 0.67) 100%
  );
}
.styles_theme-border__2RehW,
.styles_theme-fill__3wUWU {
}
.styles_theme-border__2RehW.styles_color-red__1u3lG,
.styles_theme-fill__3wUWU.styles_color-red__1u3lG {
  background: #f65082 linear-gradient(0deg, #f5477c 0%, #f86995 100%);
  box-shadow: inset 0 -2px 0 0 #d74472;
}
.styles_theme-border__2RehW.styles_color-red__1u3lG:hover,
.styles_theme-fill__3wUWU.styles_color-red__1u3lG:hover {
  background-image: linear-gradient(180deg, #f5477c 0%, #f86995 100%);
}
.styles_theme-link__1A5JK {
}
.styles_theme-link__1A5JK.styles_color-red__1u3lG {
  box-shadow: none;
  background: none;
  color: #d74472;
}
.styles_buttonsGroup__3QAem {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}
.styles_buttonsGroup__3QAem .styles_buttonsGroupItem__3h0nN {
  padding: 10px;
}
.styles_inherit-font-size__19Vvb {
  font-size: inherit !important;
}

.styles_subtitle__gw60a {
  font-weight: normal;
}

.styles_errorsTitle__3IA_A {
  text-align: center;
  font-weight: normal;
}

.styles_content__pPddX {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 30px;
  margin-bottom: 40px;
  color: #000;
}

.styles_table__2zntt {
  margin-bottom: 40px;
}

.styles_row__w1lYY {
  display: flex;
  align-items: center;
  text-align: left;
}

.styles_space__2kO-H {
  padding-top: 20px;
}

.styles_col__2jC5a {
  width: 260px;
  text-align: left;
  font-weight: bold;
  padding: 5px 0 5px;
}

.styles_colRight__3KX61 {
  width: calc(100% - 260px);
}

.styles_title__3d8dL {
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 10px;
}

.styles_errorList__3WBxq {
  list-style-type: circle;
  padding-left: 20px;
}

.styles_errorItem__clM_y {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .styles_content__pPddX {
    padding: 15px;
  }
  .styles_row__w1lYY {
    flex-direction: column;
    align-items: normal;
  }
  .styles_col__2jC5a {
    width: auto;
  }
  .styles_colRight__3KX61 {
    width: auto;
  }
  .styles_title__3d8dL {
    font-size: 20px;
  }
}

.styles_form__3daal {
  width: 300px;
  margin: 0 auto;
}

.styles_points__3AyYw {
  display: block;
  margin-top: 12px;
  padding: 0;
}
.styles_points__3AyYw li {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 9px;
  background-color: #5893f0;
  opacity: 0.5;
  border-radius: 50%;
}
.styles_points__3AyYw li.styles_active__1rixx {
  opacity: 1;
}

.styles_icon__2P5Jg::after {
  content: "";
  display: block;
  height: 1px;
  background-color: #d3dce6;
  margin-top: 10px;
  margin-bottom: 38px;
}
.styles_icon__text__2S0N7 {
  line-height: 27px;
  color: #3c4858;
  font-weight: 700;
  text-transform: uppercase;
  vertical-align: top;
  display: inline-block;
}
.styles_icon_color-green__3oNkk {
  background-color: #13ce66;
}
.styles_column_align--middle__2Z5m_ {
  vertical-align: top;
}
.styles_column_align--top__3V7dZ {
  vertical-align: top;
}
.styles_column_4-5__2PGvk {
  width: 80%;
}
.styles_column_3-5__plfZl {
  width: 60%;
}
.styles_column_2-5__1JJuf {
  width: 40%;
}
.styles_column_1-5__sljA5 {
  width: 20%;
}
.styles_column_3-4__3qAh3 {
  width: 75%;
}
.styles_column_2-4__31chX {
  width: 50%;
}
.styles_column_1-4__16s-h {
  width: 25%;
}
.styles_column_2-3__2s_oP {
  width: 66%;
}
.styles_column_1-3__3rY9h {
  width: 33%;
}
.styles_column_1-2__ERWbU {
  width: 50%;
}
.styles_blockTitle__right__39Pi- {
  flex: 0 0 auto;
}
.styles_form__tpTJk {
  max-width: 730px;
}

.styles_block__1wUEo > div {
  margin-bottom: 28px;
}

.styles_blockTitle__1eA1d {
  display: flex;
  margin-bottom: 26px;
  justify-content: space-between;
  align-items: center;
}

.styles_blockTitle__text__349De {
  color: #3c4858;
  font-size: 18px;
  font-weight: 700;
  flex: 1 1 auto;
}

.styles_row__1yBWD {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.styles_row__1yBWD + .styles_row__1yBWD {
  margin-top: 19px;
}

.styles_column__3kOa7 {
  display: table-cell;
  width: 2%;
}

.styles_column__3kOa7 + .styles_column__3kOa7 {
  padding-left: 24px;
}

.styles_column_align--bottom__1DTVU {
  vertical-align: bottom;
}

.styles_buttons__2CHKY {
  margin-top: 30px;
}

.styles_icon__2P5Jg {
  cursor: pointer;
  text-decoration: none;
}

.styles_icon__symbol__1dHBe {
  width: 27px;
  height: 27px;
  margin-right: 15px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 2;
}

.styles_icon__symbol__1dHBe > i {
  color: #fff;
  font-size: 27px;
}

.styles_error__2ht5d {
  margin-top: 20px;
  border: 1px solid #ff1f44;
  padding: 10px;
}

.styles_group-input__3bYuV.styles_theme-light__1wEON {
  border-color: #dadcde;
}
.styles_group-input__3bYuV.styles_disabled__ZNje7 {
  border-color: #dadcde;
}
.styles_group-input__3bYuV.styles_active__3SUhf {
  border-color: #11d8fb;
}
.styles_group-input__3bYuV.styles_active__3SUhf .styles_prefix-wrapper__XJSnv,
.styles_group-input__3bYuV.styles_active__3SUhf .styles_postfix-wrapper__1hluE {
  color: #3d3d3d;
}
.styles_group-input__3bYuV.styles_active__3SUhf .styles_required-label__2aFlw {
  border-bottom-color: #11d8fb;
}
.styles_label-wrapper__sOTn6 {
  position: relative;
  display: inline-block;
  width: 100%;
}
.styles_group-input__3bYuV {
  position: relative;
  display: flex;
  width: 100%;
  background-color: #fefefe;
  border: 1px solid #dedede;
}
.styles_group-input__3bYuV.styles_error__ZATHV {
  border-color: #ff1f44;
}
.styles_group-input__3bYuV.styles_error__ZATHV .styles_prefix-wrapper__XJSnv,
.styles_group-input__3bYuV.styles_error__ZATHV .styles_postfix-wrapper__1hluE {
  color: #3d3d3d;
}
.styles_group-input__3bYuV.styles_error__ZATHV .styles_required-label__2aFlw {
  border-bottom-color: #ff1f44;
}
.styles_input__1uvLV {
  color: #3c4858;
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  background-color: transparent;
  border: none;
}
.styles_input__1uvLV::placeholder {
  color: #62758f;
}
input.styles_input__1uvLV,
textarea.styles_input__1uvLV {
  padding: 18px 26px;
}
.styles_prefix-wrapper__XJSnv {
  position: relative;
  padding: 0 20px;
  padding-right: 0;
  line-height: 32px;
  border-right: none;
  font-size: 16px;
  color: #3c4858;
  margin: 10px 0;
  margin-right: -15px;
}
.styles_postfix-wrapper__1hluE {
  height: 35px;
  padding: 0 11px;
  padding-left: 0;
  line-height: 32px;
  color: rgba(61, 61, 61, 0.5);
  border-left: none;
  font-size: 16px;
}
.styles_label-text__EIWFo {
  margin-bottom: 10px;
  color: #3d3d3d;
  font-size: 12px;
}
.styles_required-label__2aFlw {
  position: absolute;
  top: 0;
  right: -2px;
  width: 0;
  height: 0;
  transform: rotate(45deg);
  border-right: 5px solid transparent;
  border-bottom: 5px solid #dedede;
  border-left: 5px solid transparent;
}
.styles_error-label__30N0o {
  position: absolute;
  top: 100%;
  right: -1px;
  padding: 8px 15px 7px 13px;
  color: #fff;
  background-color: #ff1f44;
  font-size: 10px;
  line-height: 1.4;
  z-index: 1;
}

.styles_main__e3tqr {
  max-width: 520px;
  margin: 0 auto;
  color: #3c4858;
}
.styles_main__e3tqr .styles_input__3F1Es {
  max-width: 420px;
  margin: 0 auto 24px;
}
.styles_main__e3tqr .styles_btns__3mhN7 {
  max-width: 380px;
  margin: 0 auto;
}
.styles_main__e3tqr > div {
  margin-bottom: 24px;
}

.styles_wrap__c9oZT {
  position: relative;
  line-height: 1;
  display: flex;
  flex-flow: row nowrap;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
}

.styles_wrap__c9oZT input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.styles_view__1Uul- {
  display: inline-block;
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
  line-height: 30px;
  background-color: #fff;
  border: 1px solid #c9c9c9;
  text-align: center;
}

.styles_view__1Uul- > svg {
  display: inline-block;
  vertical-align: middle;
  opacity: 1;
  width: 18px;
  font-size: 14px;
  color: #44e240;
  transition: opacity 0.05s ease-in-out;
}

input:disabled + .styles_view__1Uul- {
  opacity: 0.5;
}

input:focus + .styles_view__1Uul- {
  border-color: #11d8fb;
}

.styles_checked__24jim .styles_view__1Uul- i {
  opacity: 1;
}

.styles_label__32TY6 {
  display: inline-block;
  margin: 0 20px;
  vertical-align: middle;
  text-align: left;
  line-height: 1.4em;
  margin-bottom: -0.4em;
}

.styles_isError__13FQJ .styles_view__1Uul- {
  border-color: #ff1f44;
}

.styles_isError__13FQJ i {
  color: #ff1f44;
}

.styles_group__3E1Nl {
  display: inline-block;
  position: relative;
  margin-top: -10px;
  margin-left: -10px;
}

.styles_group__3E1Nl .styles_wrap__c9oZT {
  margin-top: 10px;
  margin-left: 10px;
}

.styles_main__Q84mN {
  color: #3c4858;
  margin-bottom: 24px;
}

.styles_main__Q84mN .styles_description__26z4U {
  font-size: 16px;
  color: #62758f;
  max-width: 520px;
  margin: 0 auto 24px;
}

.styles_main__Q84mN .styles_description_black__1vhdz {
  color: #3c4858;
}

.styles_caption__1mIPK {
  font-size: 11px;
  line-height: 14px;
}

.styles_fields__UZPRb {
  max-width: 420px;
  margin: 24px auto 0;
}

.styles_fields__UZPRb .styles_field__1XIR4,
.styles_fields__UZPRb .styles_title__3zgoA {
  margin-bottom: 24px;
}

.styles_confirm__13kLz {
  max-width: 520px;
  margin: 0 auto;
  font-size: 18px;
}

.styles_button__B-UJi {
  margin-top: 42px;
}

.styles_details__2JSoN {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 25px;
}

.styles_details__body___328T {
  margin-top: 15px;
  margin-bottom: 60px;
}

.styles_details__body___328T p {
  margin-bottom: 0;
}

.styles_details__header__title__10pY5 {
  font-weight: bold;
  display: inline-block;
  vertical-align: top;
}

.styles_details__header__arrow__2PoXS {
  font-size: 8px;
  margin-left: 7px;
  vertical-align: middle;
  display: inline-block;
}

.styles_details__title__3TNbp {
  font-weight: 800;
  margin-top: 20px;
}

.styles_details__block__28dlT {
  margin-top: 10px;
}

.styles_description__Dt1hS {
  margin-bottom: 12px;
}

.styles_accept__1rAat {
  font-weight: 700;
  margin: 12px 0 26px;
}

.styles_email__2pSGm {
  margin: 32px 0;
}

.styles_main__3EFqD .styles_description__2ODgw {
  font-size: 18px;
  color: #62758f;
}
.styles_main__3EFqD > div {
  margin-bottom: 34px;
}

.styles_main__1e-Je {
  text-align: center;
}

.styles_main__1e-Je .styles_header__3HehF {
  margin-bottom: 32px;
}

.styles_main__1e-Je .styles_content__1_3Rd {
  font-size: 16px;
  line-height: 24px;
  max-width: 520px;
  margin: 0 auto;
}

.styles_main__1e-Je .styles_content__1_3Rd p {
  margin-bottom: 12px;
}

.styles_main__1e-Je .styles_content__1_3Rd a {
  color: #548ef0;
  text-decoration: underline;
  cursor: pointer;
}

.styles_main__1e-Je .styles_form__3BeLq {
  max-width: 520px;
  margin: 32px auto 0;
  font-size: 18px;
}

.styles_reject__1g6lx button {
  cursor: pointer;
}

.styles_reject__1g6lx button:hover {
  color: #548ef0;
}

.styles_main__1qkqn {
  max-width: 540px;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
}
.styles_main__1qkqn .styles_header__2oktD {
  margin-bottom: 32px;
}
.styles_main__1qkqn .styles_content__7ngVw {
  margin-bottom: 42px;
}
.styles_main__1qkqn .styles_links__1IM1Q {
  margin-top: 30px;
}
.styles_main__1qkqn .styles_links__1IM1Q > div {
  display: inline-block;
  margin-right: 24px;
}
.styles_main__1qkqn .styles_links__1IM1Q > div:last-child {
  margin-right: 0;
}
.styles_main__1qkqn p {
  margin-bottom: 10px;
}

.styles_main__34P_I {
  max-width: 540px;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
}
.styles_main__34P_I .styles_header__1w0Gh {
  margin-bottom: 32px;
}
.styles_main__34P_I .styles_content__1YGPX {
  margin-bottom: 42px;
}
.styles_main__34P_I .styles_links__1PrRu {
  margin-top: 30px;
}
.styles_main__34P_I .styles_links__1PrRu > div {
  display: inline-block;
  margin-right: 24px;
}
.styles_main__34P_I .styles_links__1PrRu > div:last-child {
  margin-right: 0;
}
.styles_main__34P_I p {
  margin-bottom: 10px;
}

.styles_buttonWrapper__3U1oZ {
  margin-bottom: 10px;
}

.styles_link__20-eJ {
  font-size: 14px;
}

.styles_gray__3KUoM {
  color: #929499;
}

.styles_red__2gYqg {
  color: #ff1f44;
}

.styles_main__1p6z3 {
  text-align: center;
}
.styles_main__1p6z3 .styles_header__1Aw5z {
  margin-bottom: 32px;
}
.styles_main__1p6z3 .styles_form__1pk2z {
  max-width: 420px;
  margin: 0 auto;
}

.styles_main__1RKSD {
  max-width: 680px;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  padding: 0 20px;
}

.styles_main__1RKSD .styles_header__PzBA9 {
  margin-bottom: 32px;
}

.styles_main__1RKSD .styles_content__1xJV7 {
  margin-bottom: 42px;
}

.styles_main__3Y_4H {
  max-width: 680px;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  padding: 0 20px;
}

.styles_error__1IL8L {
  margin-bottom: 30px;
}

.styles_error__1IL8L b {
  color: #ff1f44;
}

.styles_listWrapper__3WPnR {
  margin-top: 25px;
  max-height: 300px;
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;
}

.styles_list__2axOh {
  text-align: left;
  padding: 5px 20px;
}

.styles_listItem__3jYhe {
  line-height: 1.25;
  font-size: 12px;
  margin: 5px 10px;
  list-style: disc;
}

.styles_user__2Dx8G {
  margin-bottom: 10px;
  line-height: 2;
}

.styles_signIn__Hak7e {
  margin: 30px 0 20px;
}

.styles_signIn__Hak7e a {
  cursor: pointer;
}

.styles_main__WNCJI {
  max-width: 680px;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  padding: 0 20px;
}

.styles_error__200ji {
  margin-bottom: 30px;
}

.styles_error__200ji b {
  color: #ff1f44;
}

.styles_user__3sN_p {
  margin: 30px 0;
  line-height: 2;
}

.styles_signIn__2fn9l {
  margin: 30px 0 20px;
}

.styles_signIn__2fn9l a {
  cursor: pointer;
}

.styles_header__2cTxO {
  margin-bottom: 28px;
}

.styles_content__13cVE {
  font-size: 16px;
  line-height: 22px;
}

@keyframes styles_autofill__oPFb2 {
  to {
    color: black;
    background: transparent;
  }
}

input:-webkit-autofill {
  animation-name: styles_autofill__oPFb2;
  animation-fill-mode: both;
}

.styles_main__3Fqh2 {
  text-align: center;
}
.styles_main__3Fqh2 .styles_header__2G26i {
  margin-bottom: 32px;
}
.styles_main__3Fqh2 .styles_form__1JuDS {
  max-width: 390px;
  margin: 0 auto;
}
.styles_main__3Fqh2 .styles_description__1ySoQ {
  margin: 20px 0;
}

.styles_main__3UFz8 {
  text-align: center;
}
.styles_main__3UFz8 .styles_header__2Nfli {
  margin-bottom: 32px;
}
.styles_main__3UFz8 .styles_form__3jnKM {
  max-width: 420px;
  margin: 0 auto;
}
.styles_main__3UFz8 .styles_description__1bF3i {
  margin: 20px 0;
}

.styles_bg__3a_jq {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.styles_color-love__hve_i {
  background-color: #eff2f7;
}

.styles_main__1knh1 {
  text-align: center;
}
.styles_main__1knh1 .styles_header__2zG4o {
  margin-bottom: 32px;
}
.styles_main__1knh1 .styles_form__1Jc76 {
  max-width: 420px;
  margin: 0 auto;
}

.styles_error__89-lh {
  text-align: center;
}
.styles_error__89-lh .styles_code__16sGz {
  font-size: 120px;
  line-height: 200px;
  font-weight: 300;
}
.styles_error__89-lh .styles_footer__2LU6x {
  font-size: 16px;
  line-height: 24px;
  margin-top: 32px;
}
.styles_error__89-lh .styles_footer__2LU6x a {
  color: #548ef0;
  text-decoration: none;
}
.styles_error__89-lh .styles_footer__2LU6x a:hover {
  text-decoration: underline;
}

