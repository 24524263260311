.main {
  display: flex;
  flex-flow: column nowrap;
  max-width: 960px;
  min-height: 100vh;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.main main {
  padding: 75px;
  background: #fff;
  box-shadow: 2px 4px 25px 1.2px rgba(204, 204, 204, 0.31);
}

.footer {
  flex: 0 0 40px;
  line-height: 40px;
  text-align: center;
}

@media (max-width: 768px) {
  .main main {
    padding: 15px;
  }
}
