.main {
  max-width: 520px;
  margin: 0 auto;
  color: #3c4858;
}
.main .input {
  max-width: 420px;
  margin: 0 auto 24px;
}
.main .btns {
  max-width: 380px;
  margin: 0 auto;
}
.main > div {
  margin-bottom: 24px;
}
