.main {
  text-align: center;
}
.main .header {
  margin-bottom: 32px;
}
.main .form {
  max-width: 390px;
  margin: 0 auto;
}
.main .description {
  margin: 20px 0;
}
