.main {
  text-align: center;
}

.header {
  margin-bottom: 32px;
}

.article {
  font-size: 16px;
  margin: 0 auto;
}

.narrow_container {
  max-width: 500px;
  margin: 0 auto;
}
