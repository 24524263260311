.main {
  max-width: 680px;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  padding: 0 20px;
}

.error {
  margin-bottom: 30px;
}

.error b {
  color: #ff1f44;
}

.user {
  margin: 30px 0;
  line-height: 2;
}

.signIn {
  margin: 30px 0 20px;
}

.signIn a {
  cursor: pointer;
}
