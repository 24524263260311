.h1 {
  color: #292b37;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
}

.h2 {
  color: #292b37;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
}

.h3 {
  color: #454545;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.h4 {
  color: #454545;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}

.h5 {
  color: #3d3d3d;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.h6 {
  color: #454545;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 10px;
}

.textTransform-uppercase {
  text-transform: uppercase;
}

.textTransform-capitalize {
  text-transform: capitalize;
}

.textTransform-lowercase {
  text-transform: lowercase;
}

.textTransform-initial {
  text-transform: initial;
}

.color-red {
  color: red;
}
