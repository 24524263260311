.main {
  max-width: 680px;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  padding: 0 20px;
}

.error {
  margin-bottom: 30px;
}

.error b {
  color: #ff1f44;
}

.listWrapper {
  margin-top: 25px;
  max-height: 300px;
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;
}

.list {
  text-align: left;
  padding: 5px 20px;
}

.listItem {
  line-height: 1.25;
  font-size: 12px;
  margin: 5px 10px;
  list-style: disc;
}

.user {
  margin-bottom: 10px;
  line-height: 2;
}

.signIn {
  margin: 30px 0 20px;
}

.signIn a {
  cursor: pointer;
}
