.bg {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.color-love {
  background-color: #eff2f7;
}
