@font-face {
  font-family: "e-Ukraine";
  src:
    url("/fonts/e-ukraine-bold.woff2") format("woff2"),
    url("/fonts/e-ukraine-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "e-Ukraine";
  src:
    url("/fonts/e-ukraine-medium.woff2") format("woff2"),
    url("/fonts/e-ukraine-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "e-Ukraine";
  src:
    url("/fonts/e-ukraine-regular.woff2") format("woff2"),
    url("/fonts/e-ukraine-regular.woff") format("woff");
  font-weight: 400;
  src:
    url("/fonts/GothamPro/GothamPro.eot?") format("eot"),
    url("/fonts/GothamPro/GothamPro.otf") format("opentype"),
    url("/fonts/GothamPro/GothamPro.woff") format("woff"),
    url("/fonts/GothamPro/GothamPro.ttf") format("truetype"),
    url("/fonts/GothamPro/GothamPro.svg#GothamPro") format("svg");
}

@font-face {
  font-family: "e-Ukraine";
  src:
    url("/fonts/e-ukraine-light.woff2") format("woff2"),
    url("/fonts/e-ukraine-light.woff") format("woff");
  font-weight: 300;
  src:
    url("/fonts/GothamPro-Light/GothamPro-Light.eot?") format("eot"),
    url("/fonts/GothamPro-Light/GothamPro-Light.otf") format("opentype"),
    url("/fonts/GothamPro-Light/GothamPro-Light.woff") format("woff"),
    url("/fonts/GothamPro-Light/GothamPro-Light.ttf") format("truetype"),
    url("/fonts/GothamPro-Light/GothamPro-Light.svg#GothamPro-Light")
      format("svg");
}

@font-face {
  font-family: "e-Ukraine";
  src:
    url("/fonts/e-ukraine-ultralight.woff2") format("woff2"),
    url("/fonts/e-ukraine-ultralight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "e-Ukraine";
  src:
    url("/fonts/e-ukraine-thin.woff2") format("woff2"),
    url("/fonts/e-ukraine-thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

body {
  color: #3c4858;
  font:
    400 12px/1.6 "GothamPro",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  min-height: 100vh;
}

a {
  color: #5893f0;
  text-decoration: none;
}

ul,
ol {
  list-style-type: none;
}

input,
textarea,
button {
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  color: inherit;
  font: inherit;
}

input,
textarea,
button {
  outline: none;
}
